import React, { useMemo, useState } from "react";
import { Button, Grid, TableContainer, Typography } from "@mui/material";
import type { AxiosError } from "axios";
import { useEntity } from "contexts/EntityProvider/EntityProvider";
import { useUserContext } from "contexts/UserProvider/UserProvider";
import useAsyncRequest from "hooks/useAsyncRequest";
import { LoadablePage } from "shared/LoadablePage";
import type { ServerLicenseDto } from "client/api/ServerLicenseApi";
import type { BaseUserGrantsApi, ServerLicenseOrSubscriptionGroupUserGrantDto } from "client/api/UserGrantApi";
import { UserGrantsTable } from "components/UserGrantTable/UserGrantsTable";
import { UserGrantsInviteTable } from "components/UserGrantsInviteTable/UserGrantsInviteTable";
import { ErrorAlert } from "components/alert/ErrorAlert";
import { PolicyAuthorized } from "components/authorization/PolicyAuthorized";
import { RbacPermissions } from "components/authorization/RbacPermissions";
import { NotFoundPage } from "pages/NotFoundPage";
import { ServerLicenseHeader } from "areas/serverLicenses/components/ServerLicenseHeader";
import { InviteUserToServerLicense } from "../components/InviteUserToServerLicense";

export type BaseUserGrantsViewProps<TUserGrant extends ServerLicenseOrSubscriptionGroupUserGrantDto> = {
  userGrantsApi: BaseUserGrantsApi<TUserGrant>;
};

type UserGrantsDetailViewProps<TUserGrant extends ServerLicenseOrSubscriptionGroupUserGrantDto> =
  BaseUserGrantsViewProps<TUserGrant>;

export function ServerLicenseUsers<TUserGrant extends ServerLicenseOrSubscriptionGroupUserGrantDto>(
  props: UserGrantsDetailViewProps<TUserGrant>
) {
  const { entity: serverLicense } = useEntity<ServerLicenseDto>();
  const { hasPermissionForAsset } = useUserContext();

  const { userGrantsApi } = props;

  const getUserGrantsRequest = useMemo(() => (id: string) => userGrantsApi.listUserGrants(id), [userGrantsApi]);
  const getInvitesRequest = useMemo(() => (id: string) => userGrantsApi.getInvites(id), [userGrantsApi]);

  const canDeleteGrant = (userGrant: TUserGrant) => {
    return (
      hasPermissionForAsset(
        RbacPermissions.ServerLicense.ManageAccessControl,
        serverLicense?.id,
        serverLicense?.subscriptionGroup?.id
      ) &&
      (userGrant.grantType === "ServerLicenseUserGrant" || userGrant.grantType === "ServerLicenseUserGrantProxy")
    );
  };

  const canRevokeInvite = () => {
    return hasPermissionForAsset(
      RbacPermissions.ServerLicense.ManageAccessControl,
      serverLicense?.id,
      serverLicense?.subscriptionGroup?.id
    );
  };

  const {
    data: userGrantsData,
    loading: loadingUserGrants,
    error: userGrantsError,
    refresh: refreshUserGrants,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  } = useAsyncRequest(getUserGrantsRequest, serverLicense?.id as string);
  const {
    data: invitesData,
    loading: loadingInvites,
    error: invitesError,
    refresh: refreshInvites,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  } = useAsyncRequest(getInvitesRequest, serverLicense?.id as string);

  const loading = loadingUserGrants || loadingInvites;
  const refreshAll = async () => {
    refreshInvites();
    refreshUserGrants();
  };

  const [showUserDialog, setShowUserDialog] = useState<boolean>(false);

  const [deleteError, setDeleteError] = useState<AxiosError>();

  const actions = [];
  actions.push(
    <PolicyAuthorized
      permission={RbacPermissions.ServerLicense.ManageAccessControl}
      assetId={serverLicense?.id}
      subscriptionGroupId={serverLicense?.subscriptionGroup?.id}
      key={serverLicense?.id}
    >
      <Button
        key={"ServerLicenseHeaderInviteUser"}
        variant={"contained"}
        size={"small"}
        onClick={() => setShowUserDialog(true)}
        sx={{ textTransform: "none" }}
        disableElevation
      >
        Invite User
      </Button>
    </PolicyAuthorized>
  );

  if (!serverLicense) {
    return <NotFoundPage />;
  }

  return (
    <>
      <ServerLicenseHeader pageTitle={"Access Control"} actions={actions} />
      {deleteError && <ErrorAlert error={deleteError} />}
      <LoadablePage loading={loading} error={userGrantsError ?? invitesError}>
        <Grid container spacing={2}>
          <Grid item xs={6} textAlign={"right"}></Grid>
          {invitesData?.entities && invitesData?.entities.length > 0 && (
            <Grid item xs={12}>
              <Typography sx={{ padding: "0 16px", fontWeight: "bold" }}>Invitations</Typography>
              <TableContainer>
                <UserGrantsInviteTable
                  userGrantInvites={invitesData?.entities}
                  grantsApi={userGrantsApi}
                  assetId={serverLicense?.id || ""}
                  canRevokeInvite={canRevokeInvite}
                  refresh={refreshInvites}
                  onDeleteError={setDeleteError}
                />
              </TableContainer>
            </Grid>
          )}
          <Grid item xs={12}>
            <TableContainer>
              <UserGrantsTable
                grants={userGrantsData?.directUserGrants}
                grantsApi={userGrantsApi}
                assetId={serverLicense?.id || ""}
                canDeleteGrant={canDeleteGrant}
                refresh={refreshUserGrants}
                onDeleteError={setDeleteError}
              />
            </TableContainer>
          </Grid>
        </Grid>
      </LoadablePage>
      <InviteUserToServerLicense
        show={showUserDialog}
        serverLicense={serverLicense}
        onSubmitComplete={async () => {
          await refreshAll();
          setShowUserDialog(false);
        }}
        onCancel={() => setShowUserDialog(false)}
      />
    </>
  );
}
