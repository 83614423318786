import React, { useMemo, useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import type { AxiosError } from "axios";
import { useUserContext } from "contexts/UserProvider/UserProvider";
import useAsyncRequest from "hooks/useAsyncRequest";
import { LoadablePage } from "shared/LoadablePage";
import type { CloudSubscriptionOrSubscriptionGroupUserGrantDto } from "client/api/UserGrantApi";
import { subscriptionGroupUserGrantsApi } from "client/api/UserGrantApi";
import type { SubscriptionGroupDto } from "client/api/common/SubscriptionGroupDto";
import { themeTokens } from "components/Theme/theme";
import { UserGrantsTable } from "components/UserGrantTable/UserGrantsTable";
import { UserGrantsInviteTable } from "components/UserGrantsInviteTable/UserGrantsInviteTable";
import { ErrorAlert } from "components/alert/ErrorAlert";
import { PolicyAuthorized } from "components/authorization/PolicyAuthorized";
import { RbacPermissions } from "components/authorization/RbacPermissions";
import { InviteUserToSubscriptionGroup } from "./InviteUserToSubscriptionGroup";

export interface AccessControlDialogProps {
  open: boolean;
  onClose: () => void;
  subscriptionGroup: SubscriptionGroupDto;
}

interface AccessControlContentProps {
  subscriptionGroup: SubscriptionGroupDto;
}

function AccessControlContent(props: AccessControlContentProps) {
  const {
    subscriptionGroup: { id, name },
  } = props;
  const getRequest = useMemo(() => (id: string) => subscriptionGroupUserGrantsApi.listUserGrants(id), []);
  const getInvitesRequest = useMemo(() => (id: string) => subscriptionGroupUserGrantsApi.getInvites(id), []);

  const { hasPermissionForAsset } = useUserContext();
  const hasDeletePermission = hasPermissionForAsset(
    RbacPermissions.SubscriptionGroup.ManageAccessControl,
    id,
    undefined
  );
  const deleteGrantCheck = (grant: CloudSubscriptionOrSubscriptionGroupUserGrantDto) => hasDeletePermission;
  const deleteInviteCheck = () => hasDeletePermission;
  const [deleteError, setDeleteError] = useState<AxiosError | undefined>();

  const { data, loading, error, refresh } = useAsyncRequest(getRequest, id);
  const {
    data: invitesData,
    loading: loadingInvites,
    error: invitesError,
    refresh: refreshInvites,
  } = useAsyncRequest(getInvitesRequest, id);

  const [showInvite, setShowInvite] = useState(false);

  const isLoading = loading || loadingInvites;
  const refreshAll = async () => {
    refreshInvites();
    refresh();
  };

  return (
    <>
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h6" color={themeTokens.color.text.secondary}>
              {name}
            </Typography>
            <Typography variant="h6">Access controls</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <PolicyAuthorized
              permission={RbacPermissions.SubscriptionGroup.ManageAccessControl}
              assetId={id}
              subscriptionGroupId={undefined}
            >
              <Button
                key="CloudSubscriptionHeaderInviteUser"
                variant="contained"
                size="small"
                onClick={() => setShowInvite(true)}
                sx={{ textTransform: "none" }}
                disableElevation
              >
                Invite User
              </Button>
            </PolicyAuthorized>
          </Stack>
        </Stack>
      </DialogTitle>
      <InviteUserToSubscriptionGroup
        show={showInvite}
        subscriptionGroupId={id}
        onCancel={() => setShowInvite(false)}
        onSubmitComplete={async () => {
          await refreshAll();
          setShowInvite(false);
        }}
      />
      <DialogContent>
        {deleteError && <ErrorAlert error={deleteError} />}
        <LoadablePage loading={isLoading} error={error ?? invitesError}>
          {invitesData?.entities && invitesData?.entities.length > 0 && (
            <UserGrantsInviteTable
              userGrantInvites={invitesData?.entities}
              grantsApi={subscriptionGroupUserGrantsApi}
              assetId={id}
              canRevokeInvite={deleteInviteCheck}
              refresh={refreshInvites}
              onDeleteError={setDeleteError}
            />
          )}
          <UserGrantsTable
            grants={data?.directUserGrants}
            grantsApi={subscriptionGroupUserGrantsApi}
            assetId={id}
            canDeleteGrant={deleteGrantCheck}
            refresh={refresh}
            onDeleteError={setDeleteError}
          />
        </LoadablePage>
      </DialogContent>
    </>
  );
}

export function AccessControlDialog(props: AccessControlDialogProps) {
  const { subscriptionGroup, onClose, open } = props;
  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth={"md"}>
      <AccessControlContent subscriptionGroup={subscriptionGroup} />
    </Dialog>
  );
}
