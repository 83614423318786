import { DateTime } from "luxon";
import type { CloudSubscriptionOutageWindowFrequency } from "utils/CloudSubscription/CloudSubscriptionOutageWindowFrequency";
import type { CloudSubscriptionStatus } from "utils/CloudSubscription/CloudSubscriptionStatus";
import type { CheckoutSessionDto } from "client/api/CheckoutSessionDto";
import type { UserGrantInviteDto } from "client/api/UserGrantApi";
import type { ListQuery } from "client/api/common/ListQuery";
import axiosClient from "../axios-client";
import type { IntLimitFeatureDto } from "./FeatureApi";
import type { UpgradePlan } from "./UpgradePlan";
import type { ListEntitiesResponse } from "./common/ListEntitiesResponse";
import type { SubscriptionGroupDto } from "./common/SubscriptionGroupDto";

export type CloudLicensingChannel =
  | SalesforceContractedCloudSubscriptionLicensingChannelDto
  | StripeSubscriptionCloudSubscriptionLicensingChannelDto
  | ZuoraCloudSubscriptionLicensingChannelDto
  | LegacyBraintreeCloudSubscriptionLicensingChannelDto
  | ImportedTrialCloudSubscriptionLicensingChannelDto
  | TrialCloudSubscriptionLicensingChannelDto
  | InfrastructureCloudSubscriptionLicensingChannelDto;

export type CloudLicensingChannelTypes = (typeof CloudLicensingChannelTypesArr)[number];

export enum CloudSubscriptionRenewalPeriod {
  Monthly = "Monthly",
  Annually = "Annually",
}

export const CloudLicensingChannelTypesKeys = {
  Salesforce: "SalesforceContractedCloudSubscriptionLicensingChannelDto",
  Stripe: "StripeSubscriptionCloudSubscriptionLicensingChannelDto",
  LegacyBraintree: "LegacyBraintreeCloudSubscriptionLicensingChannelDto",
  ImportedTrial: "ImportedTrialCloudSubscriptionLicensingChannelDto",
  Trial: "TrialCloudSubscriptionLicensingChannelDto",
  Zuora: "ZuoraCloudSubscriptionLicensingChannelDto",
  Infrastructure: "InfrastructureCloudSubscriptionLicensingChannelDto",
} as const;

export const CloudLicensingChannelTypesArr = Object.values(CloudLicensingChannelTypesKeys);

export interface SalesforceContractedCloudSubscriptionLicensingChannelDto {
  id: string;
  channelType: typeof CloudLicensingChannelTypesKeys.Salesforce;
  expiryDate: DateTime;
  salesforceContractId: string;
}

export interface StripeSubscriptionCloudSubscriptionLicensingChannelDto {
  id: string;
  channelType: typeof CloudLicensingChannelTypesKeys.Stripe;
  stripeId: string;
  customerId: string;
  status: "active" | "canceled" | "incomplete" | "incomplete_expired" | "trialing" | "unpaid" | "past_due";
  nextRenewalDate: DateTime;
  cancelAtPeriodEnd: boolean;
  freemium: boolean;
  priceCode: string | undefined;
  productName: string | undefined;
  outOfOrderTimestamp: DateTime;
}

export interface CloudSubscriptionLicensingChannelBase {
  id: string;
  channelType: CloudLicensingChannelTypes;
}

export interface RenewableLicensingChannelDto extends CloudSubscriptionLicensingChannelBase {
  /** Note for Zuora this is actually the license expiry currently, see https://github.com/OctopusDeploy/Commercial-Billing/pull/1744 */
  nextRenewalDate: DateTime;
  cancelAtPeriodEnd: boolean;
}

export interface ExpiringLicensingChannelDto extends CloudSubscriptionLicensingChannelBase {
  expiryDate: DateTime;
}

export interface InternalLicensingChannelDto extends ExpiringLicensingChannelDto {
  autoRenew: boolean;
  renewalPeriod?: CloudSubscriptionRenewalPeriod;
  comment: string;
}

export interface ZuoraCloudSubscriptionLicensingChannelDto extends RenewableLicensingChannelDto {
  channelType: typeof CloudLicensingChannelTypesKeys.Zuora;
  zuoraSubscriptionKey: string;
}

export interface LegacyBraintreeCloudSubscriptionLicensingChannelDto {
  id: string;
  channelType: typeof CloudLicensingChannelTypesKeys.LegacyBraintree;
}

export interface ImportedTrialCloudSubscriptionLicensingChannelDto extends ExpiringLicensingChannelDto {
  id: string;
  channelType: typeof CloudLicensingChannelTypesKeys.ImportedTrial;
}

export interface TrialCloudSubscriptionLicensingChannelDto extends ExpiringLicensingChannelDto {
  id: string;
  channelType: typeof CloudLicensingChannelTypesKeys.Trial;
  trialId: string;
  createdDate: DateTime;
}

export interface InfrastructureCloudSubscriptionLicensingChannelDto extends InternalLicensingChannelDto {
  channelType: typeof CloudLicensingChannelTypesKeys.Infrastructure;
}

export interface CloudSubscriptionDto<LicensingChannel = CloudLicensingChannel> {
  id: string;
  serial: string;
  isFulfilled: boolean;
  serialHash: string;
  friendlyName: string;
  cloudRegionId: string;
  dnsPrefix: string;
  outageWindowFrequency: CloudSubscriptionOutageWindowFrequency;
  outageWindowTimeZone: string;
  outageWindowStart: DateTime;
  outageWindowEnd: DateTime;
  isInstanceProvisioned: boolean;
  cloudSubscriptionStatus: CloudSubscriptionStatus;
  taskCap?: number;
  storageLimitInGb?: number;
  fileUsageInGb?: number;
  tenantLimit: IntLimitFeatureDto;
  machineLimit: IntLimitFeatureDto;
  projectLimit: IntLimitFeatureDto;
  retentionLimitInDays?: IntLimitFeatureDto;
  subscriptionGroup?: SubscriptionGroupDto;
  createdDate: DateTime;
  licensingChannel: LicensingChannel;
  customerSpecifiedMachineLimit?: number;
}

export enum AssumedInstanceProvisioningState {
  Succeeded,
  Provisioning,
  Failed,
}

const BASE_URL = "cloud-subscriptions";

export type GetCloudSubscriptionsResponse = ListEntitiesResponse<CloudSubscriptionDto>;

export const getCloudSubscriptions = async (query: ListQuery): Promise<GetCloudSubscriptionsResponse> => {
  const response = await axiosClient.get<GetCloudSubscriptionsResponse>(BASE_URL, {
    params: query,
  });
  return response.data;
};

export const getCloudSubscriptionById = async (cloudSubscriptionId: string): Promise<CloudSubscriptionDto> => {
  const response = await axiosClient.get<CloudSubscriptionDto>(`${BASE_URL}/${cloudSubscriptionId}`);
  return response.data;
};

export const getCloudSubscriptionBySerial = async (serialId: string): Promise<CloudSubscriptionDto> => {
  const response = await axiosClient.get<CloudSubscriptionDto>(`${BASE_URL}/serial/${serialId}`);
  return response.data;
};

export type CustomerPortalSessionDto = {
  url: string;
};

export const createCloudSubscriptionCheckoutUrl = async (
  cloudSubscriptionId: string,
  targetPlan: UpgradePlan,
  machineLimitOverride: number | null
): Promise<CheckoutSessionDto> => {
  const response = await axiosClient.post<CheckoutSessionDto>(`${BASE_URL}/${cloudSubscriptionId}/checkout-sessions`, {
    targetPlan,
    machineLimitOverride,
  });
  return response.data;
};

export const createCloudSubscriptionCustomerPortalUrl = async (
  cloudSubscriptionId: string,
  returnUrl: string
): Promise<CustomerPortalSessionDto> => {
  const response = await axiosClient.post<CustomerPortalSessionDto>(
    `${BASE_URL}/${cloudSubscriptionId}/customer-portal-sessions`,
    {
      returnUrl,
    }
  );
  return response.data;
};

export const inviteUserToCloudSubscription = async (
  cloudSubscriptionId: string | undefined,
  email: string,
  fullName: string,
  senderName: string,
  team: string
): Promise<UserGrantInviteDto> => {
  const response = await axiosClient.post<UserGrantInviteDto>(`${BASE_URL}/${cloudSubscriptionId}/invites`, {
    email,
    fullName,
    senderName,
    team,
  });
  return response.data;
};

export const updateCloudSubscriptionOutageWindow = async (
  cloudSubscriptionId: string,
  outageWindowStartUtc: DateTime,
  outageWindowEndUtc: DateTime
) => {
  const response = await axiosClient.put<CloudSubscriptionDto>(`${BASE_URL}/${cloudSubscriptionId}/outage-window`, {
    outageWindowStartUtc: outageWindowStartUtc.toUTC().toLocaleString(DateTime.TIME_24_WITH_SECONDS),
    outageWindowEndUtc: outageWindowEndUtc.toUTC().toLocaleString(DateTime.TIME_24_WITH_SECONDS),
  });
  return response.data;
};

export const changeCloudSubscriptionDnsPrefix = async (cloudSubscriptionId: string, dnsPrefix: string) => {
  await axiosClient.put(`${BASE_URL}/${cloudSubscriptionId}/dns-prefix`, { dnsPrefix: dnsPrefix });
};

export const updateCloudSubscriptionFriendlyName = async (cloudSubscriptionId: string, friendlyName: string) => {
  const response = await axiosClient.put<CloudSubscriptionDto>(`${BASE_URL}/${cloudSubscriptionId}/friendly-name`, {
    friendlyName,
  });
  return response.data;
};

export const cancelCloudSubscription = async (
  cloudSubscription: CloudSubscriptionDto
): Promise<CloudSubscriptionDto> => {
  const response = await axiosClient.delete<CloudSubscriptionDto>(`${BASE_URL}/${cloudSubscription.id}`);
  return response.data;
};

export const updateCustomerSpecifiedMachineLimit = async (
  cloudSubscription: CloudSubscriptionDto,
  machineLimit?: number
): Promise<CloudSubscriptionDto> => {
  const response = await axiosClient.put<CloudSubscriptionDto>(
    `${BASE_URL}/${cloudSubscription.id}/customer-specified-machine-limit`,
    {
      machineLimit,
    }
  );
  return response.data;
};

export const changeStripeProduct = async (cloudSubscriptionId: string, targetPlan: UpgradePlan): Promise<void> => {
  await axiosClient.put(`${BASE_URL}/${cloudSubscriptionId}/licensing-channel/product`, {
    cloudSubscriptionId,
    targetPlan,
  });
};

export const checkCloudSubscriptionFulfilled = async (cloudSubscriptionId: string): Promise<boolean> => {
  const response = await axiosClient.get<boolean>(`${BASE_URL}/${cloudSubscriptionId}/fulfilled`);
  return response.data;
};

export function isTrialLicensingChannel(
  licensingChannel: CloudLicensingChannel
): licensingChannel is TrialCloudSubscriptionLicensingChannelDto {
  return licensingChannel.channelType === "TrialCloudSubscriptionLicensingChannelDto";
}
