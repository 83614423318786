import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Link, Typography } from "@mui/material";
import { RoutesMap } from "RoutesMap";
import { useEntity } from "contexts/EntityProvider/EntityProvider";
import { formatStreetAddress } from "utils/formatters/StreetAddressFormatter";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import { orderThroughSales, type ProductType } from "client/api/PurchaseApi";
import type { ServerLicenseDto } from "client/api/ServerLicenseApi";
import { CustomErrorAlert, ErrorMessageTryAgainOrContactSupport } from "components/alert/CustomErrorAlert";
import type { AutocompleteAddressValue } from "areas/checkout/components/CheckoutAddressField";
import { CheckoutPrimaryButton, CheckoutSecondaryButton } from "areas/checkout/components/CheckoutButtons";
import { isDropdownAutocompleteOption } from "areas/checkout/components/CheckoutDropdownInput";
import type { CheckoutCompanyForm, CheckoutIndividualForm } from "./CheckoutPersonalDetailsStep";
import { CheckoutStepLayout } from "./CheckoutStepLayout";

export const CheckoutContactSalesReasons = {
  TaxExemption: "TaxExemption",
  CheckoutError: "CheckoutError",
} as const;

const ContactSalesReason = ({ reason }: { reason: keyof typeof CheckoutContactSalesReasons }) => {
  switch (reason) {
    case CheckoutContactSalesReasons.TaxExemption:
      return (
        <>
          <Typography variant={"body1"}>You've indicated you have a tax exemption certificate.</Typography>
          <Typography variant={"body1"} gutterBottom>
            To process your order we'll need to sight this certificate.
          </Typography>
        </>
      );
    case CheckoutContactSalesReasons.CheckoutError:
      return (
        <>
          <Typography variant={"body1"}>It looks like we encountered an issue while processing your order.</Typography>
          <Typography variant={"body1"} gutterBottom>
            We'll need to process your order manually.
          </Typography>
        </>
      );
    default:
      return null;
  }
};

const StepHeading = () => (
  <Typography variant={"h5"} fontWeight={"600"} gutterBottom>
    Contact Sales
  </Typography>
);

export const CheckoutContactSalesStep = ({
  orderSummary,
  billingProduct,
  personalDetailsStepData,
  onBack,
  reason,
}: {
  orderSummary: React.ReactElement;
  billingProduct: ProductType;
  personalDetailsStepData: CheckoutCompanyForm | CheckoutIndividualForm;
  reason: keyof typeof CheckoutContactSalesReasons;
  onBack: () => void;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderError, setOrderError] = useState<Error | undefined>(undefined);
  const [orderRequested, setOrderRequested] = useState(false);
  const navigate = useNavigate();
  const stepTestId = "checkout-contact-sales-step";

  const { entity: licenseOrSubscription } = useEntity<CloudSubscriptionDto | ServerLicenseDto>();

  const goToControlCenter = () => {
    navigate(RoutesMap.root);
  };

  const convertStreetAddressObjectToAddressLine = (streetAddress: AutocompleteAddressValue): string => {
    return formatStreetAddress({
      streetName: streetAddress.streetName,
      streetNumber: streetAddress.streetNumber ?? "",
      countryCode: streetAddress.countryCode,
    });
  };

  const onSubmit = async () => {
    setOrderError(undefined);
    setIsSubmitting(true);

    const req = (() => {
      const form = personalDetailsStepData;
      const businessAccount = form.purchasingAs === "company";

      if (businessAccount) {
        const billToContact = {
          firstName: form.billingContact.firstName,
          lastName: form.billingContact.lastName,
          email: form.billingContact.email,
          // Note: uses the company address
          address1:
            typeof form.companyContact.streetAddress === "string"
              ? form.companyContact.streetAddress
              : convertStreetAddressObjectToAddressLine(form.companyContact.streetAddress),
          country: form.companyContact.country?.value || "",
          city: form.companyContact.city,
          state: isDropdownAutocompleteOption(form.companyContact.state)
            ? form.companyContact.state.value
            : form.companyContact.state ?? "",
          postalCode: form.companyContact.postcode,
        };
        const companyName = form.companyContact.name;
        const taxId = form.taxId;

        return {
          serial: licenseOrSubscription?.serial ?? "",
          billingProduct,
          businessAccount,
          billToContact,
          companyName,
          taxId,
          reason,
        };
      } else {
        const billToContact = {
          firstName: form.billingContact.firstName,
          lastName: form.billingContact.lastName,
          email: form.billingContact.email,
          address1:
            typeof form.billingContact.streetAddress === "string"
              ? form.billingContact.streetAddress
              : convertStreetAddressObjectToAddressLine(form.billingContact.streetAddress),
          country: form.billingContact.country?.value || "",
          city: form.billingContact.city,
          state: isDropdownAutocompleteOption(form.billingContact.state)
            ? form.billingContact.state.value
            : form.billingContact.state ?? "",
          postalCode: form.billingContact.postcode,
        };

        return {
          serial: licenseOrSubscription?.serial ?? "",
          billingProduct,
          businessAccount,
          billToContact,
          reason,
        };
      }
    })();

    try {
      await orderThroughSales(req);
    } catch (e) {
      if (e instanceof Error) {
        setOrderError(e);
      }
      setIsSubmitting(false);
      return;
    }

    setOrderRequested(true);
  };

  if (orderRequested) {
    return (
      <CheckoutStepLayout orderSummary={orderSummary} testId={stepTestId}>
        <StepHeading />
        <Typography variant={"body1"} gutterBottom>
          Your request has been sent, we'll get back to you shortly.
        </Typography>
        <Box marginTop={4}>
          <CheckoutPrimaryButton disableElevation variant="contained" onClick={goToControlCenter}>
            Back to Control Center
          </CheckoutPrimaryButton>
        </Box>
      </CheckoutStepLayout>
    );
  }

  return (
    <CheckoutStepLayout orderSummary={orderSummary} testId={stepTestId}>
      <StepHeading />
      {orderError && (
        <Box marginTop={2} marginBottom={2}>
          <CustomErrorAlert title="Error processing your order" message={<ErrorMessageTryAgainOrContactSupport />} />
        </Box>
      )}
      <ContactSalesReason reason={reason} />
      <Typography variant={"body1"} gutterBottom>
        Submit your order request below and we'll get back to you in 1&ndash;3 business days. If you prefer to get in
        touch directly, email <Link href="mailto:sales@octopus.com">sales@octopus.com</Link> or phone{" "}
        <Link href="tel:+1-512-823-0256">+1 512-823-0256</Link>.
      </Typography>
      <Grid container justifyContent="space-between" alignItems="center" marginTop={4}>
        <Grid item md={6}>
          <CheckoutSecondaryButton disableElevation variant="contained" onClick={onBack}>
            Back to details
          </CheckoutSecondaryButton>
        </Grid>
        <Grid item md={6} textAlign={"right"}>
          <CheckoutPrimaryButton
            disableElevation
            variant="contained"
            type="submit"
            onClick={onSubmit}
            disabled={isSubmitting}
          >
            Submit
          </CheckoutPrimaryButton>
        </Grid>
      </Grid>
    </CheckoutStepLayout>
  );
};
