import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogContent, DialogTitle, FormControl, IconButton, TextField } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import { ErrorAlert } from "components/alert/ErrorAlert";

type RenameDialogProps = {
  value?: string;
  title: string;
  open: boolean;
  handleClose: () => void;
  submitRename: (value: string) => Promise<void>;
};
export function RenameDialog(props: RenameDialogProps) {
  const { value, title, open, handleClose, submitRename } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown | undefined>();
  const [newValue, setNewValue] = useState<string>(value ?? "");

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <>
        {error && <ErrorAlert error={error} />}
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormControl sx={{ flexGrow: 1 }} fullWidth>
            <TextField
              required
              fullWidth
              variant="outlined"
              defaultValue={value}
              onChange={(e) => setNewValue(e.target.value)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ p: 3, pt: 1 }}>
          <Button
            disabled={loading}
            variant="contained"
            sx={{ textTransform: "none" }}
            disableElevation
            onClick={async () => {
              setLoading(true);
              try {
                await submitRename(newValue);
              } catch (e) {
                setError(e);
              } finally {
                setLoading(false);
              }
            }}
          >
            {loading ? "Updating..." : "Update"}
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
