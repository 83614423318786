import React from "react";
import { Outlet, useMatch, useParams } from "react-router-dom";
import { Container } from "@mui/material";
import { EntityProvider } from "contexts/EntityProvider/EntityProvider";
import type { UserContextState } from "contexts/UserProvider/UserProvider";
import { useUserContext } from "contexts/UserProvider/UserProvider";
import useAsyncRequest from "hooks/useAsyncRequest";
import { canUpgradeCloudSubscription } from "utils/CloudSubscription/CanUpgrade";
import { IsSubscribedOrLapsed } from "utils/CloudSubscription/CloudSubscriptionStatus";
import { LoadablePage } from "shared/LoadablePage";
import { getCloudSubscriptionById } from "client/api/CloudSubscriptionApi";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import { ContactSalesDialogProvider } from "components/Dialogs/ContactSalesDialog/ContactSalesDialogProvider";
import { contactSalesReasons } from "components/Dialogs/ContactSalesDialog/ContactSalesReasons";
import NavigationSidebarLayout, { Navigation } from "components/NavigationSidebarLayout/NavigationSidebarLayout";
import { PaperLayout } from "components/PaperLayout/PaperLayout";
import { NavigationAlert } from "components/alert/NavigationAlert";
import { RbacPermissions } from "components/authorization/RbacPermissions";
import { NotFoundPage } from "pages/NotFoundPage";
import { CloudSubscriptionsRoutesMap } from "areas/cloudSubscriptions/CloudSubscriptionRoutesMap";
import { BackToDashboard } from "areas/dashboard/components/BackToDashboard";

function isActiveCloudSubscription(cloudSubscription?: CloudSubscriptionDto) {
  return cloudSubscription && IsSubscribedOrLapsed(cloudSubscription.cloudSubscriptionStatus);
}

export function getNavLinks({
  cloudSubscription,
  hasPermissionForAsset,
}: {
  cloudSubscription: CloudSubscriptionDto;
  hasPermissionForAsset: UserContextState["hasPermissionForAsset"];
}) {
  const canViewAccessControl = hasPermissionForAsset(
    RbacPermissions.CloudSubscription.ViewAccessControl,
    cloudSubscription?.id,
    cloudSubscription?.subscriptionGroup?.id
  );
  const canViewBilling = hasPermissionForAsset(
    RbacPermissions.CloudSubscription.ViewBilling,
    cloudSubscription?.id,
    cloudSubscription?.subscriptionGroup?.id
  );
  const canViewConfiguration = hasPermissionForAsset(
    RbacPermissions.CloudSubscription.ViewConfiguration,
    cloudSubscription?.id,
    cloudSubscription?.subscriptionGroup?.id
  );

  const navLinks = [
    Navigation.navItem("Overview", CloudSubscriptionsRoutesMap.detail(cloudSubscription.id).root, true),
    Navigation.navItem(
      "Billing",
      CloudSubscriptionsRoutesMap.detail(cloudSubscription.id).finance,
      true,
      canViewBilling
    ),
    Navigation.navItem(
      "Configuration",
      CloudSubscriptionsRoutesMap.detail(cloudSubscription.id).configuration,
      true,
      canViewConfiguration
    ),
  ];
  if (isActiveCloudSubscription(cloudSubscription)) {
    navLinks.push(
      Navigation.navItem(
        "Access Control",
        CloudSubscriptionsRoutesMap.detail(cloudSubscription.id).users,
        true,
        canViewAccessControl
      )
    );
  }

  return navLinks;
}

export function CloudSubscriptionLayout() {
  const { cloudSubscriptionId = "UNKNOWN" } = useParams();
  const isUpgradePage = useMatch(CloudSubscriptionsRoutesMap.detail(":cloudSubscriptionId").upgrade);
  const {
    data: cloudSubscription,
    loading,
    error,
    refresh,
  } = useAsyncRequest(getCloudSubscriptionById, cloudSubscriptionId ?? "");
  const { hasPermissionForAsset } = useUserContext();
  const canManageBilling = hasPermissionForAsset(
    RbacPermissions.CloudSubscription.ManageBilling,
    cloudSubscription?.id,
    cloudSubscription?.subscriptionGroup?.id
  );
  const canUpgrade = canUpgradeCloudSubscription(canManageBilling, cloudSubscription);
  const navLinks = (cloudSubscription && getNavLinks({ cloudSubscription, hasPermissionForAsset })) || [];

  if (error && error.response?.status === 404) {
    return <NotFoundPage />;
  }

  return (
    <EntityProvider entity={cloudSubscription} refresh={refresh}>
      <ContactSalesDialogProvider
        source={"ControlCenterCloud"}
        serial={cloudSubscription?.serial}
        reasons={contactSalesReasons}
      >
        <NavigationSidebarLayout navLinks={navLinks} preNavbarComponent={<BackToDashboard />} navLinksVisible={!error}>
          <NavigationAlert sx={{ padding: 2, marginBottom: 1 }} />
          <PaperLayout elevation={isUpgradePage ? 0 : 3}>
            <LoadablePage loading={loading} error={error}>
              <Container maxWidth={false} disableGutters={true}>
                <Outlet context={[canUpgrade]} />
              </Container>
            </LoadablePage>
          </PaperLayout>
        </NavigationSidebarLayout>
      </ContactSalesDialogProvider>
    </EntityProvider>
  );
}
