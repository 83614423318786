import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { DateText } from "@cpt/components";
import type {
  CloudSubscriptionDto,
  InfrastructureCloudSubscriptionLicensingChannelDto,
} from "client/api/CloudSubscriptionApi";
import { GridDivider } from "components/GridDivider/GridDivider";
import { LicensedEntitlementsView } from "components/LicensedEntitlementsView/LicensedEntitlementsView";
import { themeTokens } from "components/Theme/theme";
import type { LicensingChannelViewProps } from "areas/cloudSubscriptions/detail/components/finance/LicensingChannelViewProps";

type EntitlementViewProps = { cloudSubscription: CloudSubscriptionDto };
function EntitlementView(props: EntitlementViewProps) {
  const { cloudSubscription } = props;
  return <LicensedEntitlementsView license={cloudSubscription} />;
}

export function InfrastructureLicensingChannelView(
  props: LicensingChannelViewProps<InfrastructureCloudSubscriptionLicensingChannelDto>
) {
  const { channel, cloudSubscription } = props;
  const autoRenewalText = channel.autoRenew
    ? `Auto renews ${channel.renewalPeriod?.toLowerCase()}`
    : "Will not auto renew";

  return (
    <>
      <Grid item sm={3} sx={{ padding: 3 }}>
        <Typography fontWeight={700}>Plan</Typography>
      </Grid>
      <Grid item sm={9} sx={{ padding: 3 }}>
        <Stack spacing={0}>
          <Typography>Infrastructure</Typography>
          <Typography sx={{ color: themeTokens.color.text.secondary }}>
            {"License expiry"} <DateText date={channel.expiryDate} />
          </Typography>
          <Typography sx={{ color: themeTokens.color.text.secondary }}>{autoRenewalText}</Typography>
        </Stack>
      </Grid>
      <GridDivider />
      <EntitlementView cloudSubscription={cloudSubscription} />
    </>
  );
}
