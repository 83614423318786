import React from "react";
import { Typography } from "@mui/material";
import { DateTime } from "luxon";
import { designSystemColors } from "@cpt/shared";
import { CloudSubscriptionStatus } from "utils/CloudSubscription/CloudSubscriptionStatus";
import { defaultDateFormat } from "utils/formatters/DateFormatters";
import { type CloudSubscriptionDto, CloudLicensingChannelTypesKeys } from "client/api/CloudSubscriptionApi";

function getStatusText(cloudSubscription: CloudSubscriptionDto): string | React.ReactNode {
  const licensingChannel = cloudSubscription.licensingChannel;

  switch (licensingChannel.channelType) {
    case CloudLicensingChannelTypesKeys.LegacyBraintree:
      return "Inactive";
    case CloudLicensingChannelTypesKeys.Salesforce:
    case CloudLicensingChannelTypesKeys.Trial:
    case CloudLicensingChannelTypesKeys.ImportedTrial:
    case CloudLicensingChannelTypesKeys.Infrastructure:
      const prefix = licensingChannel.expiryDate < DateTime.now() ? "Expired" : "Expires";
      if (
        licensingChannel.channelType === CloudLicensingChannelTypesKeys.Infrastructure &&
        licensingChannel.autoRenew
      ) {
        return `Auto renews ${licensingChannel.renewalPeriod?.toLowerCase()}`;
      }
      return `${prefix} ${licensingChannel.expiryDate.toFormat(defaultDateFormat)}`;
    case CloudLicensingChannelTypesKeys.Stripe: {
      if (licensingChannel.status === "canceled") {
        return "Cancelled";
      } else if (licensingChannel.cancelAtPeriodEnd) {
        return `Cancels ${licensingChannel.nextRenewalDate.toFormat(defaultDateFormat)}`;
      }
      return "Active";
    }
    case CloudLicensingChannelTypesKeys.Zuora:
      if (cloudSubscription.cloudSubscriptionStatus === CloudSubscriptionStatus.Cancelled) {
        return "Cancelled";
      } else if (licensingChannel.cancelAtPeriodEnd) {
        return `Cancels ${licensingChannel.nextRenewalDate.toFormat(defaultDateFormat)}`;
      }
      return "Active";
  }
}

interface CloudSubscriptionProps {
  cloudSubscription: CloudSubscriptionDto;
}

export function CloudSubscriptionNextDate(props: CloudSubscriptionProps) {
  const { cloudSubscription } = props;

  return (
    <Typography color={designSystemColors.grey["600"]} sx={{ fontSize: "0.8125rem" }}>
      {getStatusText(cloudSubscription)}
    </Typography>
  );
}
