import Cookies from "js-cookie";
import { envConfig } from "utils/envConfig";
import { navigateToAuth } from "utils/navigationHelpers";

let isRefreshingToken = false;

const readToken = () => {
  return Cookies.get("CommercialBillingPortalOctopusIdAuth");
};

export const isAuthenticated = () => {
  const token = readToken();
  return !!token;
};

/** Returns the existing access token or acquires a new token by navigating to auth url */
export const getAccessToken = () => {
  if (envConfig.Environment === "LocalMockAuth") {
    return "mock-access-token";
  }

  const accessToken = readToken();
  const isAuthed = isAuthenticated();

  // if not authed navigate to auth url
  // check if we're already refreshing the token so we don't double up on auth requests
  if (!isAuthed && !isRefreshingToken) {
    isRefreshingToken = true;
    navigateToAuth();
  }

  return accessToken;
};
