import React, { useMemo, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField,
} from "@mui/material";
import { FormSelect } from "@cpt/components";
import { recordEntries } from "utils/recordUtils";
import type { ContactRequestSource } from "client/api/ContactRequestsApi";
import { createContactRequest } from "client/api/ContactRequestsApi";
import type { ContactSalesReason } from "components/Dialogs/ContactSalesDialog/ContactSalesReasons";
import { ContactSalesReasonLabels } from "components/Dialogs/ContactSalesDialog/ContactSalesReasons";
import { ErrorAlert } from "components/alert/ErrorAlert";
import { SubmittableForm } from "components/form/SubmittableForm";
import type { DialogProps } from "../DialogProps";

//we want a custom onClose
type ContactSalesModalProps = Omit<DialogProps, "onClose"> & {
  onClose: (data?: ContactSalesFields) => void;
  defaults?: Partial<ContactSalesFields>;
  reasons?: ContactSalesReason[];
  source: ContactRequestSource;
  serial?: string;
};

export type ContactSalesFields = {
  name: string;
  email: string;
  phone: string;
  message: string;
  reason: ContactSalesReason;
  projectQuantity?: string;
};

export function ContactSalesDialog(props: ContactSalesModalProps) {
  const { open, onClose, defaults, source, serial, reasons } = props;

  const [error, setError] = useState<Error | undefined>();

  const reasonListItems = useMemo(() => {
    return recordEntries(ContactSalesReasonLabels).filter(([k]) => {
      //if no reasons are specified, use them all
      if (!reasons || reasons.length === 0) {
        return true;
      }

      //include this entry if it's been specified
      return reasons.indexOf(k) !== -1;
    });
  }, [reasons]);

  const contactSales = async (fields: ContactSalesFields) => {
    try {
      await createContactRequest({
        ...fields,
        projectQuantity: fields.projectQuantity,
        reason: ContactSalesReasonLabels[fields.reason],
        source,
        licenseSerial: serial,
      });

      onClose(fields);
    } catch (ex) {
      if (ex instanceof Error) {
        setError(ex);
      }
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth={"md"}>
      <DialogTitle>
        Contact sales
        {onClose && (
          <IconButton
            aria-label="close"
            onClick={() => onClose()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        {error && <ErrorAlert error={error} />}
        <DialogContentText sx={{ mb: 2 }}>
          Fill in the form and we'll get back to you in 1&ndash;3 business days. If you prefer to get in touch directly,
          email <Link href="mailto:sales@octopus.com">sales@octopus.com</Link> or phone{" "}
          <Link href="tel:+1-512-823-0256">+1 512-823-0256</Link>.
        </DialogContentText>
        <SubmittableForm
          submitAction={contactSales}
          onCancel={() => onClose()}
          cancelLabel="Close"
          defaultValues={defaults}
          alwaysEnableSubmit
        >
          {(formController) => {
            const { register, formState } = formController;
            return (
              <Grid item xs={12}>
                <Stack spacing={2}>
                  <TextField id={"project-qty"} sx={{ display: "none" }} {...register("projectQuantity")} />

                  <TextField
                    id={"contact-sales-name"}
                    fullWidth
                    label={"Full name"}
                    error={!!formState.errors?.name}
                    helperText={formState?.errors?.name?.message}
                    {...register("name", { required: true })}
                  />

                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          id={"contact-sales-email"}
                          fullWidth
                          type={"email"}
                          label={"Work email address"}
                          error={!!formState.errors?.email}
                          helperText={formState?.errors?.email?.message}
                          {...register("email", { required: true })}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id={"contact-sales-phone"}
                          fullWidth
                          type={"tel"}
                          label={"Phone number"}
                          error={!!formState.errors?.phone}
                          helperText={formState?.errors?.phone?.message}
                          {...register("phone", { required: true })}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <FormSelect
                      name={"reason"}
                      rules={{ required: true }}
                      label={"What can we help with?"}
                      labelId={"contact-sales-reason-label"}
                      selectItems={reasonListItems}
                      fullWidth
                    />
                  </Box>
                  <TextField
                    id={"contact-sales-message"}
                    fullWidth
                    multiline
                    minRows={2}
                    label={"Message"}
                    error={!!formState.errors?.message}
                    helperText={formState?.errors?.message?.message}
                    {...register("message", { required: true })}
                  />
                </Stack>
              </Grid>
            );
          }}
        </SubmittableForm>
      </DialogContent>
    </Dialog>
  );
}
