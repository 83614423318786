import React, { createContext, useContext } from "react";
import type { PropsWithChildren } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { useFrontendMonitoring } from "contexts/FrontendMonitoringProvider/FrontendMonitoringProvider";
import useAsyncRequest from "hooks/useAsyncRequest";
import type { UserDto } from "client/api/UserApi";
import { getUserInfo } from "client/api/UserApi";
import type { RbacPermission } from "components/authorization/RbacPermissions";

type UserProviderProps = PropsWithChildren<unknown>;
export type UserContextState = {
  hasPermissionForAsset: (
    expectedPermission: RbacPermission,
    assetId: string | undefined,
    subscriptionGroupId: string | undefined
  ) => boolean;
  userInfo?: UserDto;
  refresh: () => void;
};

const UserContext = createContext<UserContextState>({
  hasPermissionForAsset: (
    expectedPermission: RbacPermission,
    assetId: string | undefined,
    subscriptionGroupId: string | undefined
  ) => false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refresh: () => {},
});

export const useUserContext = (): UserContextState => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return useContext(UserContext);
};

export function UserProvider(props: UserProviderProps) {
  const { children } = props;
  const { data: userInfo, loading, refresh } = useAsyncRequest(getUserInfo);

  const hasPermissionForAsset = (
    expectedPermission: RbacPermission,
    assetId: string | undefined,
    subscriptionGroupId: string | undefined
  ): boolean => {
    if (loading || !userInfo || !assetId) {
      return false;
    }
    const { permissions } = userInfo;

    const subscriptionGroupPermissions = permissions[subscriptionGroupId ?? ""] ?? [];
    const hasExpectedSubscriptionGroupPermission = subscriptionGroupPermissions.includes(expectedPermission);

    const assetPermissions = permissions[assetId ?? ""] ?? [];
    const hasExpectedAssetPermission = assetPermissions.includes(expectedPermission);

    return hasExpectedSubscriptionGroupPermission || hasExpectedAssetPermission;
  };

  const { frontendMonitoring } = useFrontendMonitoring();
  if (userInfo?.id) {
    frontendMonitoring.identifyUser(userInfo.id);
  }

  return (
    <UserContext.Provider value={{ userInfo, hasPermissionForAsset, refresh }}>
      {loading ? (
        <Grid item xs={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "2rem" }}>
          <CircularProgress />
          <p>Loading user data...</p>
        </Grid>
      ) : (
        children
      )}
    </UserContext.Provider>
  );
}
