import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { FontAwesomeSvgIcon } from "@cpt/components";

type ActionDialogProps = {
  title: string;
  description?: string;
  show: boolean;
  confirmText: string;
  confirmColor?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  cancelText?: string;
  onConfirm: () => Promise<void> | void;
  onCancel: () => Promise<void> | void;
  children?: JSX.Element;
};

export function ActionDialog(props: ActionDialogProps) {
  const { title, description, show, onConfirm, onCancel, cancelText, confirmText, confirmColor, children } = props;

  const handleOnCancel = async () => {
    await onCancel();
  };

  const handleOnConfirm = async () => {
    await onConfirm();
  };

  return show ? (
    <Dialog open={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {title}
        {handleOnCancel ? (
          <IconButton
            aria-label="close"
            onClick={handleOnCancel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <FontAwesomeSvgIcon icon={faTimes} />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        {!!description && <DialogContentText id="alert-dialog-description">{description}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions sx={{ padding: 3 }}>
        <Button
          variant={"outlined"}
          onClick={() => handleOnCancel()}
          autoFocus
          sx={{ textTransform: "none" }}
          disableElevation
        >
          {cancelText || "Cancel"}
        </Button>
        <Button
          variant={"contained"}
          color={confirmColor || "error"}
          onClick={() => handleOnConfirm()}
          sx={{ textTransform: "none" }}
          disableElevation
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
}
