import React from "react";
import { Grid } from "@mui/material";
import { useEntity } from "contexts/EntityProvider/EntityProvider";
import type { ServerLicenseDto } from "client/api/ServerLicenseApi";
import { GridDivider } from "components/GridDivider/GridDivider";
import { LicensedEntitlementsView } from "components/LicensedEntitlementsView/LicensedEntitlementsView";
import { OrdersFinanceLine } from "components/OrdersFinanceLine/OrdersFinanceLine";
import { themeTokens } from "components/Theme/theme";
import { PolicyAuthorized } from "components/authorization/PolicyAuthorized";
import { RbacPermissions } from "components/authorization/RbacPermissions";
import type { ServerLicenseProps } from "areas/serverLicenses/ServerLicenseProps";
import { ServerLicenseHeader } from "areas/serverLicenses/components/ServerLicenseHeader";
import { SalesforceContractedServerLicensingChannelView } from "areas/serverLicenses/detail/components/finance/SalesforceContractedServerLicensingChannelView";
import { StripeSubscriptionServerLicensingChannelView } from "areas/serverLicenses/detail/components/finance/StripeSubscriptionServerLicensingChannelView";
import { TrialServerLicensingChannelView } from "./components/finance/TrialServerLicensingChannelView";
import { ZuoraServerLicensingChannelView } from "./components/finance/ZuoraServerLicensingChannelView";

function ServerLicensingChannelView(props: ServerLicenseProps) {
  const { serverLicense } = props;

  switch (serverLicense.licensingChannel.channelType) {
    case "ZuoraServerLicensingChannelDto":
      return <ZuoraServerLicensingChannelView serverLicense={serverLicense} channel={serverLicense.licensingChannel} />;
    case "SalesforceContractedServerLicensingChannelDto":
      return <SalesforceContractedServerLicensingChannelView />;
    case "StripeSubscriptionServerLicensingChannelDto":
      return (
        <StripeSubscriptionServerLicensingChannelView
          serverLicense={serverLicense}
          channel={serverLicense.licensingChannel}
        />
      );
    case "TrialServerLicensingChannelDto":
      return <TrialServerLicensingChannelView serverLicense={serverLicense} channel={serverLicense.licensingChannel} />;
    case "TemporaryServerLicensingChannelDto":
      return null;
  }
}

function EntitlementView(props: ServerLicenseProps) {
  const { serverLicense } = props;
  return <LicensedEntitlementsView license={serverLicense} />;
}

export function ServerLicenseFinance() {
  const { entity: serverLicense } = useEntity<ServerLicenseDto>();

  if (!serverLicense) return <></>;

  return (
    <PolicyAuthorized
      permission={RbacPermissions.ServerLicense.ViewBilling}
      assetId={serverLicense.id}
      subscriptionGroupId={serverLicense.subscriptionGroup?.id}
    >
      <ServerLicenseHeader pageTitle={"Billing"} />
      <Grid container sx={{ borderTop: "1px solid", borderColor: themeTokens.color.border.secondary }}>
        <ServerLicensingChannelView serverLicense={serverLicense} />
        <GridDivider />
        <EntitlementView serverLicense={serverLicense} />
        <GridDivider />
        <OrdersFinanceLine serverLicense={serverLicense} />
      </Grid>
    </PolicyAuthorized>
  );
}
