import React from "react";
import { Card, Link, Stack, Typography } from "@mui/material";
import { CloudSubscriptionStatus } from "utils/CloudSubscription/CloudSubscriptionStatus";
import { isTrialLicensingChannel } from "utils/CloudSubscriptionLicensingChannelTypeHelpers";
import { getHostname } from "utils/formatters/DnsFormatters";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import { CloudSubscriptionStatusIcon } from "components/Dashboard/CloudSubscriptions/CloudSubscriptionStatusIcon";
import { themeTokens } from "components/Theme/theme";
import { UserCanAccessCloudInstance } from "areas/cloudSubscriptions/components/UserCanAccessCloudInstance";
import { IconAndText } from "areas/cloudSubscriptions/detail/components/overview/IconAndText";

export function CloudSubscriptionStatusCard(cloudSubscription: CloudSubscriptionDto) {
  const hostname = cloudSubscription && getHostname(cloudSubscription.dnsPrefix);
  const isCancelled = cloudSubscription.cloudSubscriptionStatus == "Cancelled";
  let status = "";
  switch (cloudSubscription.cloudSubscriptionStatus) {
    case CloudSubscriptionStatus.Subscribed:
      status = isTrialLicensingChannel(cloudSubscription.licensingChannel)
        ? "Active"
        : cloudSubscription.cloudSubscriptionStatus;
      break;
    default:
      status = cloudSubscription.cloudSubscriptionStatus || "";
      break;
  }

  return (
    <Card sx={{ padding: 2, flex: 1 }}>
      <Typography sx={{ color: themeTokens.color.text.secondary }}>Status</Typography>
      <Stack direction="row" alignItems="center">
        <IconAndText
          icon={<CloudSubscriptionStatusIcon cloudSubscription={cloudSubscription} />}
          primary={status}
          secondary={
            isCancelled ? undefined : (
              <UserCanAccessCloudInstance subscription={cloudSubscription} withoutAccess={<>&nbsp;</>}>
                <Link target="_blank" href={`https://${hostname}`} sx={{ textDecoration: "none", fontWeight: "500" }}>
                  View Instance
                </Link>
              </UserCanAccessCloudInstance>
            )
          }
        />
      </Stack>
    </Card>
  );
}
