import type { ReactNode } from "react";
import React, { useState } from "react";
import { Grid, Stack, styled, Typography, Box } from "@mui/material";
import type { DateTime } from "luxon";
import { Settings } from "luxon";
import { TimeText } from "@cpt/components";
import { useEntity } from "contexts/EntityProvider/EntityProvider";
import { CloudStaticIpAddressPool } from "utils/CloudStaticIpAddressPool";
import {
  cloudSubscriptionOutageWindowFrequencyDescriptions,
  isDailyOutageWindow,
} from "utils/CloudSubscription/CloudSubscriptionOutageWindowFrequency";
import type { CloudSubscriptionOutageWindowFrequency } from "utils/CloudSubscription/CloudSubscriptionOutageWindowFrequency";
import { IsSubscribedOrLapsed } from "utils/CloudSubscription/CloudSubscriptionStatus";
import { ExternalUriMap } from "utils/ExternalUriMap";
import { formatCloudRegionForDisplay } from "utils/formatters/CloudRegionFormatters";
import { getHostname } from "utils/formatters/DnsFormatters";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import { updateCloudSubscriptionFriendlyName } from "client/api/CloudSubscriptionApi";
import { RenameDialog } from "components/Dialogs/RenameDialog";
import { ExternalLink } from "components/ExternalLink/ExternalLink";
import { LinkButton } from "components/LinkButton/LinkButton";
import { themeTokens } from "components/Theme/theme";
import { PolicyAuthorized } from "components/authorization/PolicyAuthorized";
import { RbacPermissions } from "components/authorization/RbacPermissions";
import { CloudSubscriptionHeader } from "areas/cloudSubscriptions/components/CloudSubscriptionHeader";
import { ActiveCloudSubscriptionOnly } from "areas/cloudSubscriptions/detail/components/ActiveCloudSubscriptionOnly";
import { CloudSubscriptionNewDnsPrefixDialog } from "areas/cloudSubscriptions/detail/components/CloudSubscriptionNewDnsPrefixDialog";
import { CloudSubscriptionOutageWindowDialog } from "areas/cloudSubscriptions/detail/components/CloudSubscriptionOutageWindowDialog";
import { FileUsageLineItem } from "./components/overview/FileUsageLineItem";

function Divider() {
  return (
    <Grid
      item
      xs={12}
      sx={{
        "--Grid-borderWidth": "1px",
        borderTop: "var(--Grid-borderWidth) solid",
        borderColor: themeTokens.color.border.secondary,
      }}
    />
  );
}

type ItemLineProps = {
  title: string;
  content: ReactNode;
  action?: ReactNode;
  hideDivider?: boolean;
};

function ItemLine(props: ItemLineProps) {
  const { title, content, action, hideDivider } = props;

  return (
    <>
      <Grid item sm={3} sx={{ padding: 3 }}>
        <Typography fontWeight={700}>{title}</Typography>
      </Grid>
      <Grid item sm={6} sx={{ padding: 3, display: "flex", alignItems: "center" }}>
        <Stack spacing={0}>{content}</Stack>
      </Grid>
      {action && (
        <Grid item sm={3} textAlign={"right"} sx={{ padding: 3 }}>
          {action}
        </Grid>
      )}
      {!hideDivider && <Divider />}
    </>
  );
}

const CodeText = styled("code")(({ theme }) => ({
  margin: 0,
  padding: "0.1rem",
  color: theme.palette.primary.dark,
}));

const getOutageWindowContent = (
  frequency: CloudSubscriptionOutageWindowFrequency,
  timeZone: string,
  start: DateTime,
  end: DateTime
): ReactNode => {
  return (
    <>
      <Typography paragraph gutterBottom>
        In order to update your instance to newer releases of Octopus Deploy, we may occasionally need to take it
        offline for a short period.
      </Typography>
      {isDailyOutageWindow(frequency) && <DailyOutageWindowContent start={start} end={end} />}
      {!isDailyOutageWindow(frequency) && (
        <MonthlyOutageWindowContent frequency={frequency} timeZone={timeZone} start={start} end={end} />
      )}
    </>
  );
};

function DailyOutageWindowContent({ start, end }: { start: DateTime; end: DateTime }) {
  return (
    <>
      <Typography variant="subtitle1">You have nominated the following maintenance window:</Typography>
      <Box>
        <TimeText time={start.toLocal()} fontWeight={"bold"} hideRelativeIndicator />
        <Box component={"span"} mx={1.5}>
          &mdash;
        </Box>
        <TimeText time={end.toLocal()} fontWeight={"bold"} hideRelativeIndicator />
      </Box>
      <Typography variant={"caption"} color={themeTokens.color.text.tertiary}>
        In your timezone
      </Typography>
    </>
  );
}

function MonthlyOutageWindowContent({
  frequency,
  timeZone,
  start,
  end,
}: {
  frequency: CloudSubscriptionOutageWindowFrequency;
  timeZone: string;
  start: DateTime;
  end: DateTime;
}) {
  const labelFormat = "h:mm a";
  const localeOptions = {
    locale: Settings.defaultLocale,
    outputCalendar: Settings.defaultOutputCalendar,
    numberingSystem: Settings.defaultNumberingSystem,
  };

  return (
    <>
      <Typography variant="subtitle1">You have nominated the following maintenance window:</Typography>
      <Box>
        <Typography>{cloudSubscriptionOutageWindowFrequencyDescriptions[frequency]}</Typography>
        <Typography component="span" fontWeight="bold">
          {start.toFormat(labelFormat, localeOptions)}
        </Typography>
        <Box component={"span"} mx={1.5}>
          &mdash;
        </Box>
        <Typography component="span" fontWeight="bold">
          {end.toFormat(labelFormat, localeOptions)}
        </Typography>
        <Typography component="span">&nbsp;{timeZone}</Typography>
      </Box>
    </>
  );
}
export function CloudSubscriptionConfiguration() {
  const { entity: cloudSubscriptionEntity, refresh } = useEntity<CloudSubscriptionDto>();
  const [showOutageWindowDialog, setShowOutageWindowDialog] = useState<boolean>(false);
  const [showNewDnsChangeDialog, setShowNewDnsChangeDialog] = useState<boolean>(false);
  const [showRenameDisplayNameDialog, setShowRenameDisplayNameDialog] = useState<boolean>(false);

  if (!cloudSubscriptionEntity) {
    // This shouldn't happen, but we need to handle based on type hints
    return null;
  }

  const cloudSubscription: CloudSubscriptionDto = cloudSubscriptionEntity;
  const isActiveCloudSubscription = IsSubscribedOrLapsed(cloudSubscription.cloudSubscriptionStatus);
  const canEditOutageWindow = isActiveCloudSubscription && isDailyOutageWindow(cloudSubscription.outageWindowFrequency);

  const handleOutageWindowChange = async () => {
    setShowOutageWindowDialog(false);
    if (refresh) {
      refresh();
    }
  };

  const handleOutageWindowDialogClose = () => {
    setShowOutageWindowDialog(false);
  };

  const handleOutageWindowDialogOpen = () => {
    setShowOutageWindowDialog(true);
  };

  const openNewDnsPrefixDialog = () => setShowNewDnsChangeDialog(true);
  const closeNewDnsPrefixDialog = () => setShowNewDnsChangeDialog(false);
  const onDnsPrefixChanged = async () => {
    closeNewDnsPrefixDialog();
    if (refresh) {
      refresh();
    }
  };

  const handleRenameDisplayNameOpen = () => setShowRenameDisplayNameDialog(true);
  const handleRenameDisplayNameClose = () => setShowRenameDisplayNameDialog(false);

  async function handleDisplayNameChange(value: string) {
    await updateCloudSubscriptionFriendlyName(cloudSubscription.id, value);
    setShowRenameDisplayNameDialog(false);
    if (refresh) {
      refresh();
    }
  }

  return (
    <>
      <CloudSubscriptionHeader pageName="Configuration" />
      <Grid container spacing={0} sx={{ borderTop: "1px solid", borderColor: themeTokens.color.border.secondary }}>
        <ItemLine
          title="Display name"
          content={cloudSubscription.friendlyName}
          action={
            <PolicyAuthorized
              assetId={cloudSubscription.id}
              subscriptionGroupId={cloudSubscription.subscriptionGroup?.id}
              permission={RbacPermissions.CloudSubscription.ManageConfiguration}
            >
              <LinkButton onClick={handleRenameDisplayNameOpen}>Rename</LinkButton>
            </PolicyAuthorized>
          }
        />
        <ItemLine
          title="Instance URL"
          content={getHostname(cloudSubscription?.dnsPrefix ?? "")}
          action={
            <ActiveCloudSubscriptionOnly subscription={cloudSubscription}>
              <PolicyAuthorized
                assetId={cloudSubscription.id}
                subscriptionGroupId={cloudSubscription.subscriptionGroup?.id}
                permission={RbacPermissions.CloudSubscription.ManageConfiguration}
              >
                <LinkButton onClick={openNewDnsPrefixDialog}>Change URL</LinkButton>
              </PolicyAuthorized>
            </ActiveCloudSubscriptionOnly>
          }
        />
        <ItemLine
          title="Resource usage"
          content={
            <FileUsageLineItem
              limitInGb={cloudSubscription.storageLimitInGb ?? 0}
              usageInGb={cloudSubscription.fileUsageInGb ?? 0}
            />
          }
          action={
            <ExternalLink fontSize="16px" href={ExternalUriMap.octopusComAcceptableUsagePolicyPath} textAlign={"right"}>
              More Info
            </ExternalLink>
          }
        />
        <ItemLine title="Region" content={formatCloudRegionForDisplay(cloudSubscription.cloudRegionId ?? "")} />
        <ItemLine
          title="Static IP addresses"
          content={
            <CodeText>{(CloudStaticIpAddressPool[cloudSubscription.cloudRegionId ?? ""] ?? []).join(", ")}</CodeText>
          }
        />
        <ItemLine
          title="Maintenance window"
          content={getOutageWindowContent(
            cloudSubscription.outageWindowFrequency,
            cloudSubscription.outageWindowTimeZone,
            cloudSubscription.outageWindowStart,
            cloudSubscription.outageWindowEnd
          )}
          action={
            canEditOutageWindow && (
              <PolicyAuthorized
                assetId={cloudSubscription.id}
                subscriptionGroupId={cloudSubscription.subscriptionGroup?.id}
                permission={RbacPermissions.CloudSubscription.ManageConfiguration}
              >
                <LinkButton onClick={handleOutageWindowDialogOpen}>Change Window</LinkButton>
              </PolicyAuthorized>
            )
          }
          hideDivider
        />
      </Grid>
      <PolicyAuthorized
        assetId={cloudSubscription.id}
        permission={RbacPermissions.CloudSubscription.ManageConfiguration}
        subscriptionGroupId={cloudSubscription.subscriptionGroup?.id}
      >
        {canEditOutageWindow && (
          <CloudSubscriptionOutageWindowDialog
            cloudSubscription={cloudSubscription}
            open={showOutageWindowDialog}
            onConfirm={handleOutageWindowChange}
            onClose={handleOutageWindowDialogClose}
          />
        )}
        <CloudSubscriptionNewDnsPrefixDialog
          cloudSubscription={cloudSubscription}
          open={showNewDnsChangeDialog}
          onConfirm={onDnsPrefixChanged}
          onClose={closeNewDnsPrefixDialog}
        />
        <RenameDialog
          value={cloudSubscription.friendlyName}
          title={"Change display name"}
          open={showRenameDisplayNameDialog}
          handleClose={handleRenameDisplayNameClose}
          submitRename={handleDisplayNameChange}
        />
      </PolicyAuthorized>
    </>
  );
}
