import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { Container } from "@mui/material";
import { EntityProvider } from "contexts/EntityProvider/EntityProvider";
import type { UserContextState } from "contexts/UserProvider/UserProvider";
import { useUserContext } from "contexts/UserProvider/UserProvider";
import useAsyncRequest from "hooks/useAsyncRequest";
import { isServerTrial } from "utils/isTrial";
import { LoadablePage } from "shared/LoadablePage";
import type { ServerLicenseDto } from "client/api/ServerLicenseApi";
import { getServerLicenseById } from "client/api/ServerLicenseApi";
import { ContactSalesDialogProvider } from "components/Dialogs/ContactSalesDialog/ContactSalesDialogProvider";
import { contactSalesReasons } from "components/Dialogs/ContactSalesDialog/ContactSalesReasons";
import NavigationSidebarLayout, { Navigation } from "components/NavigationSidebarLayout/NavigationSidebarLayout";
import { PaperLayout } from "components/PaperLayout/PaperLayout";
import { NavigationAlert } from "components/alert/NavigationAlert";
import { RbacPermissions } from "components/authorization/RbacPermissions";
import { NotFoundPage } from "pages/NotFoundPage";
import { BackToDashboard } from "areas/dashboard/components/BackToDashboard";
import { ServerLicensesRoutesMap } from "areas/serverLicenses/ServerLicensesRoutesMap";
import { ServerLicenseExpiryBanner } from "areas/serverLicenses/components/ServerLicenseExpiryBanner";

export function getNavLinks({
  serverLicense,
  hasPermissionForAsset,
}: {
  serverLicense: ServerLicenseDto;
  hasPermissionForAsset: UserContextState["hasPermissionForAsset"];
}) {
  const canViewBilling = hasPermissionForAsset(
    RbacPermissions.ServerLicense.ViewBilling,
    serverLicense?.id,
    serverLicense?.subscriptionGroup?.id
  );

  const canViewAccessControl = hasPermissionForAsset(
    RbacPermissions.ServerLicense.ViewAccessControl,
    serverLicense?.id,
    serverLicense?.subscriptionGroup?.id
  );

  const navLinks = [
    Navigation.navItem("Overview", ServerLicensesRoutesMap.detail(serverLicense.id).root, true),
    Navigation.navItem("Billing", ServerLicensesRoutesMap.detail(serverLicense.id).finance, true, canViewBilling),
    Navigation.navItem(
      "Access Control",
      ServerLicensesRoutesMap.detail(serverLicense.id).users,
      true,
      canViewAccessControl
    ),
  ];

  return navLinks;
}

export function ServerLicenseLayout() {
  let { serverLicenseId } = useParams();
  serverLicenseId = serverLicenseId || "UNKNOWN";
  const { data: serverLicense, loading, error, refresh } = useAsyncRequest(getServerLicenseById, serverLicenseId ?? "");
  const { hasPermissionForAsset } = useUserContext();
  const navLinks = (serverLicense && getNavLinks({ serverLicense, hasPermissionForAsset })) || [];

  if (error && error.response?.status === 404) {
    return <NotFoundPage />;
  }

  const isTrial = serverLicense && isServerTrial(serverLicense.licensingChannel.channelType);

  return (
    <EntityProvider entity={serverLicense} refresh={refresh}>
      <ContactSalesDialogProvider
        source={"ControlCenterSelfHosted"}
        serial={serverLicense?.serial}
        reasons={contactSalesReasons}
      >
        <NavigationSidebarLayout navLinks={navLinks} preNavbarComponent={<BackToDashboard />} navLinksVisible={!error}>
          <NavigationAlert sx={{ padding: 2, marginBottom: 1 }} />
          {serverLicense && !isTrial && <ServerLicenseExpiryBanner expiryDate={serverLicense.expiryDate} />}
          <PaperLayout>
            <LoadablePage loading={loading} error={error}>
              <Container maxWidth={false} disableGutters={true}>
                <Outlet />
              </Container>
            </LoadablePage>
          </PaperLayout>
        </NavigationSidebarLayout>
      </ContactSalesDialogProvider>
    </EntityProvider>
  );
}
